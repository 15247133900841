import React, { useContext } from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as style from 'styles/components/press/logo.module.scss';
import usePressLogoPage from 'data/queries/usePressLogoPage';
import { LangContext } from 'context/LangContext';
import TextEditorRender from 'components/utils/TextEditorRender';
import FormattedMessage from 'components/utils/FormattedMessage';

const PressLogo = () => {
    const { lang } = useContext(LangContext);
    const pressLogoPage = usePressLogoPage();

    return (
        <div className={`${style.container} wrapper`}>
            <h1 className="title-lg bold">
                <FormattedMessage id="press.pressPhotos" />
            </h1>

            {pressLogoPage.content[lang.translationKey] && (
                <div className={style.introduction}>
                    <TextEditorRender content={pressLogoPage.content[lang.translationKey]._rawContent} />
                </div>
            )}

            {pressLogoPage.logos.map(logo => (
                <div key={logo._key} className={style.logo__container}>
                    <div className={style.logo__image}>
                        <ImageGatsby
                            {...logo.logo}
                            alt={logo.title[lang.translationKey]}
                            title={logo.title[lang.translationKey]}
                        />
                    </div>

                    <div className={style.logo__files}>
                        <h2 className="text-lg bold">{logo.title[lang.translationKey]}</h2>
                        {logo.files.map(item => (
                            <div key={item._key}>
                                <a className={style.logo__link} href={item.file.asset.url} rel="noreferrer">
                                    {item.filename[lang.translationKey]}
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PressLogo;
