import { useStaticQuery, graphql } from 'gatsby';

const usePressLogoPage = () => {
    const { sanityPagePressLogo: pressLogoPage } = useStaticQuery(graphql`
        query {
            sanityPagePressLogo {
                content {
                    en {
                        _rawContent
                    }
                    se {
                        _rawContent
                    }
                }
                logos {
                    _key
                    logo {
                        ...ImageWithPreview
                    }
                    title {
                        en
                        se
                    }
                    files {
                        _key
                        file {
                            asset {
                                url
                            }
                        }
                        filename {
                            en
                            se
                        }
                    }
                }
            }
        }
    `);

    return pressLogoPage;
};

export default usePressLogoPage;
